import { useState, useEffect } from "react";
import {
  Popover,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import servicoOsApi from "../../api/servicoOsApi";
import {
  DadosServicoOS,
  FiltroBuscaServicoByUsuario,
} from "../../model/types/ServicoOSTypes";
import dayjs from "dayjs";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import { PlayArrowRounded } from "@mui/icons-material";
import { colors } from "../../theme";
import MiniInfo from "./MiniInfo";
import Titulo3 from "./Titulo3";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { formatMilliseconds } from "../../helpers/helper";
import { useSnack } from "../../redux/snack/snackHooks";
import { useSelector } from "react-redux";
import { RootAppState } from "../../redux/store";

const CornerMenu = () => {
  const mostrarSnack = useSnack();
  const [anchorEl, setAnchorEl] = useState(null);
  const [servicos, setServicos] = useState<DadosServicoOS[]>([]);
  const [tempoEmExecucao, setTempoEmExecucao] = useState<number[]>([]);
  const { appState } = useSelector((state: RootAppState) => state.appState);

  const [filtros, setFiltros] = useState<FiltroBuscaServicoByUsuario>(
    new FiltroBuscaServicoByUsuario()
  );

  useEffect(() => {
    buscarServicos();
  }, []);

  const handleClick = (event: any) => {
    buscarServicos();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTempoEmExecucao((prevTempo) =>
        servicos.map((item, index) => {
          if (item.pausado) {
            return prevTempo[index] || item.tempoDecorrido;
          } else {
            const inicio = new Date(item.dataTempoInicial).getTime();
            return Math.floor(Date.now() - inicio + item.tempoDecorrido);
          }
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [servicos]);

  const buscarServicos = async () => {
    try {
      const res = await servicoOsApi.getServicos({
        ...filtros,
        jaInciados: true,
      });
      setServicos(res);
    } catch (erro: any) {
      console.log("erro ao buscar servicos", erro);
      mostrarSnack("Aconteceu um erro ao buscar os servicos", "error");
    }
  };

  const pausarServico = async (item: DadosServicoOS, index: number) => {
    try {
      await servicoOsApi.pausarServico(item);

      const novosServicos = [...servicos];
      novosServicos[index] = {
        ...novosServicos[index],
        pausado: true,
        dataTempoPausado: dayjs().toISOString(),
      };
      setServicos(novosServicos);

      buscarServicos();
    } catch (erro: any) {
      console.log("Não foi possível pausar o serviço!", erro);
      mostrarSnack("Não foi possível pausar o serviço!", "error");
    }
  };

  const iniciarServico = async (item: DadosServicoOS, index: number) => {
    try {
      await servicoOsApi.iniciarServico(item);
      const novosServicos = [...servicos];
      novosServicos[index] = {
        ...novosServicos[index],
        pausado: false,
        dataTempoInicial: dayjs().toISOString(),
      };
      setServicos(novosServicos);
    } catch (erro: any) {
      console.log("Não foi possível retomar o serviço!", erro);
      mostrarSnack("Não foi possível retomar o serviço!", "error");
    }
  };

  return (
    <div
      style={{ position: "fixed", bottom: 16, right: 16, borderRadius: "25px" }}
    >
      <IconButton
        onClick={handleClick}
        sx={{ width: 40, height: 40 }}
        style={{
          backgroundColor: "#3f51b5",
          color: "white",
        }}
      >
        <AccessTimeIcon style={{ fontSize: 40 }} />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "500px",
            height: "700px",
            padding: "16px",
            borderRadius: "25px",
          },
          xs: { width: "100px", height: "100px", borderRadius: "25px" },
        }}
      >
        <AppBar
          position="static"
          style={{ width: "100%", borderRadius: "25px" }}
        >
          <Toolbar style={{ justifyContent: "center", alignItems: "center" }}>
            <div style={{ textAlign: "center" }}>
              <Typography variant="h6" style={{ margin: "16px 0" }}>
                Serviços em andamento
              </Typography>
            </div>
          </Toolbar>
        </AppBar>

        {Array.isArray(servicos) && servicos.length > 0 ? (
          servicos.map((item, index) => (
            <Paper
              key={item.idServico}
              style={{ margin: "12px", padding: "10px", borderRadius: "25px" }}
            >
              <Grid container spacing={1}>
                {item.pausado ? (
                  <Grid item xs={12} style={{ textAlign: "right" }}>
                    <MiniInfo
                      style={{ marginTop: 3 }}
                      color={colors.primaryLight}
                    >
                      PAUSADO
                    </MiniInfo>
                  </Grid>
                ) : (
                  <Grid item xs={12} style={{ textAlign: "right" }}>
                    <MiniInfo
                      style={{ marginTop: 3 }}
                      color={colors.lightGreen}
                    >
                      EM EXECUÇÃO
                    </MiniInfo>
                  </Grid>
                )}

                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Titulo3>{item.nomeServico}</Titulo3>
                </Grid>

                {!item.pausado && (
                  <Grid
                    container
                    item
                    style={{
                      display: "flex ",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={10}>
                      <Typography component="h2">
                        Iniciado em:{" "}
                        {dayjs(item.dataTempoInicial).format(
                          "DD/MM/YYYY - HH:mm"
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={1} md={2} style={{ textAlign: "right" }}>
                      <IconButton
                        onClick={() => {
                          pausarServico(item, index);
                        }}
                      >
                        <PauseCircleFilledIcon style={{ fontSize: "34px" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography>
                    Tempo decorrido{" "}
                    {formatMilliseconds(
                      item.pausado
                        ? item.tempoDecorrido
                        : tempoEmExecucao[index] || item.tempoDecorrido
                    )}
                  </Typography>
                </Grid>

                {item.pausado && (
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <IconButton
                      onClick={() => {
                        iniciarServico(item, index);
                      }}
                    >
                      <PlayArrowRounded style={{ fontSize: "34px" }} />
                    </IconButton>
                  </Grid>
                )}

                <Grid item xs={12} style={{ display: "flex" }}>
                  <PersonPinIcon /> {item.nomeResponsavel}
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography variant="body1" style={{ textAlign: "center" }}>
            Nenhum serviço em andamento.
          </Typography>
        )}
      </Popover>
    </div>
  );
};

export default CornerMenu;
