import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { Empresa } from "../../model/entities/Empresa";
import { useEmpresa } from "../../redux/empresa/empresaHooks";

interface CfgPerfilProps {
  children: ReactNode;
}

type CfgEmpresaContext = {
  empresaEditar: Empresa;
  setEmpresaEditar: Dispatch<SetStateAction<Empresa>>;

  logo: File | null;
  setLogo: Dispatch<SetStateAction<File | null>>;
};

export const CfgEmpresaContext = createContext({} as CfgEmpresaContext);

export function CfgEmpresaProvider({ children }: CfgPerfilProps) {
  const [empresa] = useEmpresa();
  const [empresaEditar, setEmpresaEditar] = useState<Empresa>(
    empresa ?? new Empresa()
  );

  const [logo, setLogo] = useState<File | null>(null);

  return (
    <CfgEmpresaContext.Provider
      value={{
        empresaEditar,
        setEmpresaEditar,
        logo,
        setLogo,
      }}
    >
      {children}
    </CfgEmpresaContext.Provider>
  );
}
