import { Empresa } from "../model/entities/Empresa";
import api from "./api";

const ROUTE = "empresa";

class empresaApi {
  async insert(dados: Empresa) {
    try {
      const res = await api.post<Empresa>(`/${ROUTE}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(idEmpresa: number, empresa: Empresa, logo: File | null) {
    try {
      const dados = new FormData();
      dados.append("id", String(empresa.id || "")); // id é opcional, então trate como vazio se não houver
      dados.append("razaoSocial", empresa.razaoSocial);
      dados.append("cnpj", empresa.cnpj);
      dados.append("nomeFantasia", empresa.nomeFantasia);
      dados.append("telefone", empresa.telefone);
      dados.append("email", empresa.email);
      dados.append("cep", empresa.cep);
      dados.append("logradouro", empresa.logradouro);
      dados.append("cidade", empresa.cidade);
      dados.append("bairro", empresa.bairro);
      dados.append("numeroEndereco", empresa.numeroEndereco);
      dados.append("nomeResposavel", empresa.nomeResposavel);
      dados.append("telefoneResponsavel", empresa.telefoneResponsavel);
      dados.append(
        "utilizaIntegracaoZap",
        String(empresa.utilizaIntegracaoZap)
      );
      dados.append("urlApiZap", empresa.urlApiZap);

      dados.append(
        "utilizaIntegracaoCalendar",
        String(empresa.utilizaIntegracaoCalendar)
      );
      dados.append("urlApiCalendar", empresa.urlApiCalendar);

      if (logo) {
        dados.append("logo", logo);
      }

      const res = await api.put<Empresa>(`/${ROUTE}/${idEmpresa}`, dados);

      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getOne() {
    try {
      const res = await api.get<Empresa>(`/${ROUTE}`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new empresaApi();
