import { useContext, useState } from "react";
import GridCenter from "../../components/Generics/GridCenter";
import { Grid } from "@mui/material";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import Titulo2 from "../../components/Generics/Titulo2";
import { useSnack } from "../../redux/snack/snackHooks";
import CheckBoxGeneric from "../../components/Generics/CheckBoxGeneric";
import { CfgEmpresaContext } from "../../contexts/CfgEmpresaContext/CfgEmpresaContext";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";

const IntegracoesEmpresaPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const mostrarSnack = useSnack();

  const { empresaEditar, setEmpresaEditar } = useContext(CfgEmpresaContext);

  return (
    <GridCenter>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBoxGeneric
            label={"Utiliza integração com Whaths App"}
            value={empresaEditar.utilizaIntegracaoZap}
            onChange={(x) => {
              setEmpresaEditar({ ...empresaEditar, utilizaIntegracaoZap: x });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <CheckBoxGeneric
            label={"Utiliza integração com Google Calendar"}
            value={empresaEditar.utilizaIntegracaoCalendar}
            onChange={(x) => {
              setEmpresaEditar({
                ...empresaEditar,
                utilizaIntegracaoCalendar: x,
              });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          {empresaEditar.utilizaIntegracaoZap && (
            <TextFieldGeneric
              label={"Url api zap"}
              value={empresaEditar.urlApiZap}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, urlApiZap: value })
              }
            />
          )}
        </Grid>

        <Grid item xs={6}>
          {empresaEditar.utilizaIntegracaoCalendar && (
            <TextFieldGeneric
              label={"Url api google calendar"}
              value={empresaEditar.urlApiCalendar}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, urlApiCalendar: value })
              }
            />
          )}
        </Grid>
      </Grid>
    </GridCenter>
  );
};

export default IntegracoesEmpresaPage;
