import { RouteType } from "../config";
import grupoServico from "./grupoServico";
import grupoInicio from "./grupoInicio";
import grupoClientes from "./grupoClientes";
import grupoOrcamento from "./grupoOrcamento";
import grupoFinanceiro from "./grupoFinanceiro";
import grupoOrdemServico from "./grupoOrdemServico";
import grupoConfiguracao from "./grupoConfiguracao";
import grupoAgenda from "./grupoAgenda";
import grupowWhatsApp from "./grupoWhatsApp";
import grupoPortifolio from "./grupoPortifolio";

const rotas: RouteType[] = [
  grupoInicio,
  // grupoPortifolio,
  grupoAgenda,
  grupoOrcamento,
  grupoOrdemServico,

  grupoClientes,
  grupoServico,
  grupoFinanceiro,
  grupowWhatsApp,
  grupoConfiguracao,
];

export default rotas;
