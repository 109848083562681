import React, { useState } from "react";
import "dayjs/locale/pt-br";
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Transacao } from "../../../model/entities/Transacao";
import GridCenter from "../../../components/Generics/GridCenter";
import { formatarDinheiro } from "../../../helper";
import PaidIcon from "@mui/icons-material/Paid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  TIPO_LANCAMENTO_CAIXA_CREDITO,
  TIPO_LANCAMENTO_CAIXA_DEBITO,
} from "../../../constants";
import IconRenderer from "../../../components/Generics/IconRenderer";
import MiniInfo from "../../../components/Generics/MiniInfo";
import theme, { colors } from "../../../theme";
import transacaoApi from "../../../api/transacaoApi";
import Titulo2 from "../../../components/Generics/Titulo2";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TodayIcon from "@material-ui/icons/Today";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ModalCadastrarTransacao from "../Depesa/ModalCadastrarTransacao";
import DialogConfirmGeneric from "../../../components/Generics/DialogConfirmGeneric";
import ModalEditarTransacao from "../Depesa/ModalEditarTransacao";
import { useSnack } from "../../../redux/snack/snackHooks";

type Props = {
  transacoes: Transacao[];
  onReload: () => void;
  dataInicio: dayjs.Dayjs;
  setDataInicio: (value: dayjs.Dayjs) => void;

  dataFim: dayjs.Dayjs;
  setDataFim: (value: dayjs.Dayjs) => void;

  setBalanco: (value: string) => void;
  balanco: string;
};

const TransacoesCaixa: React.FC<Props> = (props) => {
  const {
    transacoes,
    onReload,
    dataInicio,
    setDataInicio,
    dataFim,
    setDataFim,
    balanco,
    setBalanco,
  } = props;
  const [openConfirmacaoPagamento, setOpenConfirmacaoPagamento] =
    useState(false);

  const [transacaoSelecionada, setTransacaoSelecionada] =
    useState<Transacao | null>(null);

  const [transacaoSelecionadaExcluir, setTransacaoSelecionadaExcluir] =
    useState<Transacao | null>(null);

  const [openModalNovaReceita, setOpenMoldaNovaReceita] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [openModalNovaDespesa, setOpenMoldaNovaDespesa] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const isSmallSScreen = useMediaQuery(theme.breakpoints.down("md"));
  const mostrarSnack = useSnack();

  const pagarTransacao = async () => {
    try {
      if (!transacaoSelecionada) return;

      await transacaoApi.update(transacaoSelecionada.id, {
        atualizarVarias: false,
        contaCaixaId: 1,
        dataEmissao: transacaoSelecionada.dataEmissao,
        dataVencimento: transacaoSelecionada.dataVencimento,
        idTransacaoRecorrente: transacaoSelecionada.idTransacaoRecorrente ?? 0,
        pago: true,
        tipoTransacao:
          transacaoSelecionada.tipoTransacao === TIPO_LANCAMENTO_CAIXA_CREDITO
            ? TIPO_LANCAMENTO_CAIXA_CREDITO
            : TIPO_LANCAMENTO_CAIXA_DEBITO,
        titulo: transacaoSelecionada.titulo,
        valor: transacaoSelecionada.valor,
        categoriaID: 1,
        formaPagamentoId: transacaoSelecionada.formaPagamento.id ?? 0,
      });
      onReload();
      mostrarSnack("Pagamento registrado!", "success");
      setOpenConfirmacaoPagamento(false);
      setTransacaoSelecionada(null);
    } catch (erro: any) {
      mostrarSnack("Erro ao fazer pagamento da transação selecionada", "error");
    }
  };

  const totalTransacoesCredito = transacoes
    .filter((item) => item.tipoTransacao === TIPO_LANCAMENTO_CAIXA_CREDITO)
    .reduce((soma, item) => (soma += item.valor), 0);

  const totalTransacoesDebito = transacoes
    .filter((item) => item.tipoTransacao === TIPO_LANCAMENTO_CAIXA_DEBITO)
    .reduce((soma, item) => (soma += item.valor), 0);

  const capitalizeMonth = (date: any) => {
    const month = date.format("MMMM");
    return `${month.charAt(0).toUpperCase() + month.slice(1)} - ${date.format(
      "YYYY"
    )}`;
  };

  const deletar = async (transacao: Transacao) => {
    try {
      if (!transacao) {
        mostrarSnack("Selecione a transação que deseja remover", "info");
        return;
      }
      if (transacao.idTransacaoRecorrente) {
        setOpenAlert(true);
      } else {
        remover(false);
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao cadastrar transacao", "error");
      console.log("Erro ao cadastrar transacao", erro);
    }
  };

  const remover = async (excluirRecorrentes: boolean) => {
    try {
      if (!transacaoSelecionada) {
        mostrarSnack("Selecione a transação que deseja remover uai", "info");
        return;
      }

      await transacaoApi.delete(
        transacaoSelecionada.id,
        excluirRecorrentes,
        transacaoSelecionada.idTransacaoRecorrente
          ? transacaoSelecionada.idTransacaoRecorrente
          : 0
      );
      mostrarSnack(
        `${excluirRecorrentes ? "Transações excluidas" : "Transação excluida"}
        `,
        "success"
      );
      onReload();
    } catch (erro: any) {
      mostrarSnack(
        `Não foi posível excluir ${
          excluirRecorrentes ? "transações recorrentes" : "transação"
        }`,
        "error"
      );
      console.error("Não foi posível excluir transações recorrentes", erro);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Titulo2>Balanço financeiro</Titulo2>
      </Grid>

      <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
        <Chip
          style={{ padding: "10px", margin: "5px" }}
          icon={<TodayIcon />}
          color={balanco === "dia" ? "primary" : "default"}
          label={"Díario"}
          onClick={() => {
            setBalanco("dia");
          }}
        />

        <Chip
          label={"Semanal"}
          style={{ padding: "10px", margin: "5px" }}
          icon={<DateRangeIcon />}
          color={balanco === "semana" ? "primary" : "default"}
          onClick={() => {
            setBalanco("semana");
          }}
        />

        <Chip
          label={"Mensal"}
          style={{ padding: "10px", margin: "5px" }}
          icon={<CalendarTodayIcon />}
          color={balanco === "mes" ? "primary" : "default"}
          onClick={() => {
            setBalanco("mes");
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: "right" }}>
        <IconButton
          style={{ color: colors.lightGreen }}
          size="small"
          onClick={() => setOpenMoldaNovaReceita(true)}
        >
          Nova Receita
          <AddCircleIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "right" }}>
        <IconButton
          style={{ color: colors.lightRed }}
          size="small"
          onClick={() => setOpenMoldaNovaDespesa(true)}
        >
          Nova Despesa
          <AddCircleIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: "15px",
              padding: "5px",
            }}
          >
            {balanco === "mes" ? (
              <div>
                <DatePickerGeneric
                  label={"Mês"}
                  views={["year", "month"]}
                  value={dayjs(dataInicio)}
                  setValue={(value) => {
                    setDataInicio(value.startOf("month"));
                    setDataFim(value.endOf("month"));
                  }}
                />
              </div>
            ) : (
              <div>
                <DatePickerGeneric
                  label={"Data inicio"}
                  value={dataInicio}
                  setValue={(value) => {
                    setDataInicio(value);
                  }}
                />

                <DatePickerGeneric
                  label={"Data fim"}
                  value={dataFim}
                  setValue={(value) => {
                    setDataFim(value);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ maxHeight: "550px", overflow: "auto" }}>
        {transacoes.map((transacao) => (
          <GridCenter>
            <Paper
              style={{
                borderRadius: "20px",
                margin: "12px 0px",
                padding: "12px",
                width: isSmallSScreen ? "100%" : "75%",
              }}
              key={transacao.id}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={1}>
                  {transacao.categoria && (
                    <IconRenderer iconName={transacao.categoria.icone} />
                  )}
                </Grid>

                <Grid item md={8}>
                  <Typography fontWeight={"bold"}>
                    {transacao.titulo}
                  </Typography>
                </Grid>

                <Grid item md={3} style={{ textAlign: "right" }}>
                  <Typography fontWeight={"bold"}>
                    {transacao.formaPagamento.descricao}
                  </Typography>
                </Grid>

                <Grid item md={10} />

                <Grid item md={2} style={{ textAlign: "right" }}>
                  <Typography
                    fontWeight={"bold"}
                    color={
                      transacao.tipoTransacao === TIPO_LANCAMENTO_CAIXA_DEBITO
                        ? "RED"
                        : "green"
                    }
                  >
                    {transacao.tipoTransacao ===
                      TIPO_LANCAMENTO_CAIXA_DEBITO && <> - </>}
                    R$ {formatarDinheiro(transacao.valor)}
                  </Typography>
                </Grid>

                <Grid item md={12} style={{ textAlign: "right" }}>
                  {!transacao.pago && (
                    <IconButton
                      onClick={() => {
                        setTransacaoSelecionada(transacao);
                        setOpenConfirmacaoPagamento(true);
                      }}
                    >
                      <PaidIcon />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => {
                      setTransacaoSelecionada(transacao);
                      deletar(transacao);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>

                  {!transacao.idOrdemServico && (
                    <IconButton
                      onClick={() => {
                        setTransacaoSelecionada(transacao);
                        setOpenModalEditar(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Grid>

                <Grid item xs={9} />

                <Grid item md={3} style={{ textAlign: "right" }}>
                  {transacao.pago &&
                    transacao.tipoTransacao ===
                      TIPO_LANCAMENTO_CAIXA_DEBITO && (
                      <MiniInfo color={colors.lightGreen}>PAGO</MiniInfo>
                    )}

                  {transacao.pago &&
                    transacao.tipoTransacao ===
                      TIPO_LANCAMENTO_CAIXA_CREDITO && (
                      <MiniInfo color={colors.lightGreen}>JÁ RECEBIDO</MiniInfo>
                    )}

                  {!transacao.pago &&
                    transacao.tipoTransacao ===
                      TIPO_LANCAMENTO_CAIXA_DEBITO && (
                      <MiniInfo color={colors.red}>A PAGAR</MiniInfo>
                    )}

                  {!transacao.pago &&
                    transacao.tipoTransacao ===
                      TIPO_LANCAMENTO_CAIXA_CREDITO && (
                      <MiniInfo color={colors.lightBlue}>A RECEBER</MiniInfo>
                    )}
                </Grid>
              </Grid>
            </Paper>
          </GridCenter>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: theme.spacing(2),
            borderRadius: "25px",
            background: "rgba(255, 255, 255, 0.8)", // Fundo branco semitransparente
            textAlign: "right",
            color: "black",
          }}
        >
          <Grid item xs={12} md={4} style={{ textAlign: "left" }}>
            <Typography variant="h6" fontWeight={"bold"}>
              Saldo
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{ textAlign: isSmallSScreen ? "left" : "center" }}
          >
            {balanco === "mes" && (
              <Typography variant="h6" fontWeight={"bold"}>
                {capitalizeMonth(dataInicio)}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography
              fontWeight="bold"
              fontSize={20}
              style={{
                color:
                  totalTransacoesDebito > totalTransacoesCredito
                    ? "red"
                    : "green",
              }}
            >
              R${" "}
              {formatarDinheiro(totalTransacoesCredito - totalTransacoesDebito)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {(openModalNovaDespesa || openModalNovaReceita) && (
        <ModalCadastrarTransacao
          transacao={
            openModalNovaDespesa
              ? TIPO_LANCAMENTO_CAIXA_DEBITO
              : TIPO_LANCAMENTO_CAIXA_CREDITO
          }
          open={openModalNovaDespesa || openModalNovaReceita}
          onClose={() => {
            setOpenMoldaNovaDespesa(false);
            setOpenMoldaNovaReceita(false);
            onReload();
          }}
        />
      )}

      {openConfirmacaoPagamento && (
        <DialogConfirmGeneric
          txtRespostaPositiva={"Confirmar"}
          txtRespostaNegativa={"Cancelar"}
          open={openConfirmacaoPagamento}
          cabecalho={"Confirmar pagamento ?"}
          texto={
            <span>
              Confirma o pagamento de {transacaoSelecionada?.titulo} no valor de
              ${formatarDinheiro(transacaoSelecionada?.valor ?? 0)}
            </span>
          }
          onResposta={(resposta) => {
            if (resposta && transacaoSelecionada) {
              pagarTransacao();
            } else {
              setOpenConfirmacaoPagamento(false);
              setTransacaoSelecionada(null);
            }
          }}
        />
      )}

      {openModalEditar && transacaoSelecionada && (
        <ModalEditarTransacao
          transacao={transacaoSelecionada}
          open={Boolean(openModalEditar && transacaoSelecionada)}
          onClose={() => {
            setOpenModalEditar(false);
            setTransacaoSelecionada(null);
            onReload();
          }}
        />
      )}

      {openAlert && (
        <DialogConfirmGeneric
          open={openAlert}
          cabecalho={"Excluir transações recorrentes"}
          txtRespostaNegativa="Apenas esta"
          txtRespostaPositiva="Também as futuras"
          texto={`Você deseja excluir apenas esta transação, ou as transações futuras ?`}
          onResposta={(resposta) => {
            remover(resposta);
            setOpenAlert(false);
          }}
        />
      )}
    </Grid>
  );
};

export default TransacoesCaixa;
