import React, { useContext, useState } from "react";

import "dayjs/locale/pt-br";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { useSnack } from "../../redux/snack/snackHooks";
import Container from "../../components/Generics/Container";
import theme from "../../theme";
import FormularioEmpresa from "./FormularioEmpresaPage";
import IntegracoesEmpresaPage from "./IntegracoesEmpresaPage";
import { useEmpresa } from "../../redux/empresa/empresaHooks";
import empresaApi from "../../api/empresaApi";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import Titulo2 from "../../components/Generics/Titulo2";
import { CfgEmpresaContext } from "../../contexts/CfgEmpresaContext/CfgEmpresaContext";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EmpresaPage: React.FC = () => {
  const mostrarSnack = useSnack();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false);
  const [, setEmpresa] = useEmpresa();

  const { empresaEditar, logo } = useContext(CfgEmpresaContext);

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const salvar = async () => {
    try {
      setLoading(true);
      setEmpresa(empresaEditar);

      //em adição de campo novo, sempre alterar dentro da api
      const res = await empresaApi.update(1, empresaEditar, logo);
      setEmpresa(res);
      mostrarSnack("Informações atualizadas", "success");
      setLoading(false);
    } catch (erro: any) {
      console.error("Erro ao atualizar informações da empresa", erro);
      mostrarSnack(`Erro ao atualizar informações da empresa.`, "error");
      setLoading(false);
    }
  };

  return (
    <Container page style={{ borderRadius: "20px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="full width tabs example"
      >
        <Tab label="Dados empresa" {...a11yProps(0)} />
        <Tab label="Integrações" {...a11yProps(1)} />
      </Tabs>

      {loading && (
        <ModalGeneric open={loading} onClose={() => {}}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Titulo2>Atulizando informações da empresa ...</Titulo2>
            </Grid>
          </Grid>
        </ModalGeneric>
      )}

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <FormularioEmpresa />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <IntegracoesEmpresaPage />
        </TabPanel>
      </SwipeableViews>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <ButtonGeneric label={"Salvar"} onClick={salvar} />
      </Grid>
    </Container>
  );
};

export default EmpresaPage;
